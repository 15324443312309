<template>
  <div class="outer-wrapper">
    <div class="title-wrapper">
      <span>风险点整改信息管理</span>
    </div>
    <div class="inner-wrapper">
      <div class="editor-wrapper">
        <Tabs>
          <TabPane label="风险点及整改信息" icon="ios-people">
            <query-table
              :columnsFormatData="columnsFormatData"
              askData="correctManage"
              :refreshData="update_count"
              main_width="1260px"
            ></query-table>
          </TabPane>
        </Tabs>
      </div>
    </div>

    <Modal
      v-model="scModal"
      width="800"
      :mask-closable="false"
      @on-cancel="modal_cancel"
    >
      <p slot="header" class="sc-header">
        <Icon type="ios-list-box" />
        <span>整改信息录入</span>
      </p>
      <div class="sc-content-wrapper">
        <div class="form-wrapper">
          <Form
            ref="alterCorrect"
            :model="currentCorrect"
            :rules="ruleValidateAlter"
            :label-width="150"
          >
          <FormItem label="线路名称" prop="line_name">
            <Input
              type="text"
              v-model="currentCorrect.line_name"
              placeholder="填写"
              disabled
            />
          </FormItem>
          <FormItem label="子系统" prop="major_system">
            <Input
              type="text"
              v-model="currentCorrect.major_system"
              placeholder="填写"
              disabled
            />
          </FormItem>
          <FormItem label="风险点" prop="risk_content">
            <Input
              type="textarea"
              v-model="currentCorrect.risk_content"
              placeholder="填写"
              disabled
            />
          </FormItem>
          <FormItem label="专家建议" prop="solution_proposal">
            <Input
              type="textarea"
              v-model="currentCorrect.solution_proposal"
              placeholder="填写专家建议"
              disabled
            />
          </FormItem>
          <FormItem label="整改措施" prop="correct_operation">
            <Input type="textarea" v-model="currentCorrect.correct_operation" placeholder="填写整改措施" />
          </FormItem>
          <FormItem label="责任部门/单位" prop="res_department">
            <Input type="text" v-model="currentCorrect.res_department" placeholder="填写责任部门/单位" />
          </FormItem>
          <FormItem label="分管领导" prop="res_leader">
            <Input type="text" v-model="currentCorrect.res_leader" placeholder="填写分管领导" />
          </FormItem>
          <FormItem label="负责人手机号" prop="contact_phone">
            <Input type="text" v-model="currentCorrect.contact_phone" placeholder="填写负责人手机号便于短信通知" />
          </FormItem>
          <FormItem label="时间期限" prop="limit_date">
            <input
              type="date"
              placeholder=""
              style="width: 200px"
              v-model="currentCorrect.limit_date"
            />
          </FormItem>
          <FormItem label="节点目标" prop="recent_goal">
            <Input type="textarea" v-model="currentCorrect.recent_goal" placeholder="填写节点目标" />
          </FormItem>
          <FormItem label="进展情况" prop="recent_stat_description">
            <Input type="textarea" v-model="currentCorrect.recent_stat_description" placeholder="填写进展情况" />
          </FormItem>
          <FormItem label="落实整改状态" prop="risk_correct_stat">
            <Select v-model="currentCorrect.risk_correct_stat" style="width: 60%;">
                    <Option
                      v-for="(ele,index) in option.correctStatChoices"
                      :key="index"
                      :value="ele.value"
                    >{{ele.label}}</Option>
                  </Select>
          </FormItem>
            <FormItem label="整改附件上传：" prop="pdfFile">
              <Upload
                v-if="fileAlter === null"
                ref="upload"
                :before-upload="handleUploadAlter"
                :on-success="handleSuccess"
                action="/"
              >
                <Button class="button-wrapper" icon="ios-cloud-upload-outline"
                  >请选择上传文件</Button
                >
              </Upload>
              <div v-if="fileAlter !== null" class="file-space-wrapper">
                <div class="file-space">
                  <div class="file-icon-wrapper">
                    <Icon type="ios-document-outline" :size="50" />
                  </div>
                  <div class="fp-inner">
                    <p>{{ fileAlter.name }}</p>
                    <p>{{ fileAlter.size / 1024 + "K" }}</p>
                  </div>
                  <div class="del-icon-wrapper">
                    <Button
                      class="del-button"
                      @click="handleRemove"
                      type="text"
                      icon="ios-trash"
                      size="large"
                    ></Button>
                  </div>
                </div>
              </div>
            </FormItem>
          </Form>
        </div>
      </div>
      <div slot="footer">
        <Button size="large" @click="modal_save('alterCorrect')">确定</Button>
        <Button size="large" @click="modal_cancel">取消</Button>
      </div>
    </Modal>
        <Modal
      v-model="swModal"
      width="800"
      :mask-closable="false"
      @on-cancel="modal_cancel"
    >
      <p slot="header" class="sc-header">
        <Icon type="ios-list-box" />
        <span>整改信息确认</span>
      </p>
      <div class="sc-content-wrapper">
        <div class="form-wrapper">
          <Form
            ref="confirmCorrect"
            :model="currentCorrect"
            :rules="ruleValidateAlter"
            :label-width="150"
          >
          <FormItem label="线路名称" prop="line_name">
            <Input
              type="text"
              v-model="currentCorrect.line_name"
              placeholder="填写"
              disabled
            />
          </FormItem>
          <FormItem label="风险点" prop="risk_content">
            <Input
              type="textarea"
              v-model="currentCorrect.risk_content"
              placeholder="填写"
              disabled
            />
          </FormItem>
          <FormItem label="整改措施" prop="correct_operation">
            <Input type="textarea" v-model="currentCorrect.correct_operation" placeholder="填写整改措施" disabled/>
          </FormItem>
          <FormItem label="确认意见" prop="correct_confirm">
            <Input type="textarea" v-model="currentCorrect.confirm_remark" placeholder="填写确认意见"/>
          </FormItem>
          <FormItem label="整改确认状态" prop="risk_confirm_stat">
            <Select v-model="currentCorrect.confirm_stat" style="width: 60%;">
                    <Option
                      v-for="(ele,index) in option.confirmStatChoices"
                      :key="index"
                      :value="ele.value"
                    >{{ele.label}}</Option>
                  </Select>
          </FormItem>
          </Form>
        </div>
      </div>
      <div slot="footer">
        <Button size="large" @click="modal_save('confirmCorrect')">确定</Button>
        <Button size="large" @click="modal_cancel">取消</Button>
      </div>
    </Modal>
  </div>
</template>
<script>
import queryTable from "@/components/Common/QueryTable.vue";

let userToken = localStorage.getItem("current_user_token");

function correctFac() {
  return {
    id: 0,
    risk_id:null,
    risk_content:'',
    line_name:"",
    major_system:"",
    solution_proposal:"",
    correct_operation:'',
    res_department:'',
    res_leader:'',
    recent_goal:'',
    recent_stat_description:'',
    risk_correct_stat:'',
    contact_phone:'',
    limit_date:null,
    confirm_remark:"",
    confirm_stat:'',
    pdfFile: "",
  };
}

export default {
  name: "correctManage",
  components: {
    queryTable,
  },
  props: {},
  data() {
    return {
      askData: "correctManage",
      update_count: 0,
      columnsFormatData: [
       {
          title: "线路名称",
          key: "line_name",
          width: 90,
          align: "center",
          render: (h, p) => {
            let url = "/baseInfo/line_c/" + p.row.line_code;
            return h(
              "a",
              {
                attrs: {
                  href: url,
                  target: "_blank",
                },
              },
              p.row.line_name
            );
          },
        },
        {
          title: "评价时间",
          key: "evaluate_year",
          width: 105,
          sortable: true,
          align: "center",
        },
        {
          title: "风险点",
          key: "risk_content",
          align: "center",
          minWidth: 120,
          render: (h, p) => {
            let url = "/conclusion/risk_c/" + p.row.risk_id;
            return h(
              "a",
              {
                attrs: {
                  href: url,
                  target: "_blank",
                },
              },
              p.row.risk_content
            );
          },
        },
        {
          title: "整改要求",
          key: "correct_demand",
          width: 110,
          align: "center",
          filters: [
            {
              label: "计划整改",
              value: "计划整改",
            },
            {
              label: "急需整改",
              value: "急需整改",
            },
            {
              label: "专项研究",
              value: "专项研究",
            },
            {
              label: "加强维护管理",
              value: "加强维护管理",
            },
            {
              label: "加强人员培训",
              value: "加强人员培训",
            },
          ],
          filterMethod(value, row) {
            return row.correct_demand.indexOf(value) > -1;
          },
        },
        {
          title: "分管领导",
          key: "res_leader",
          width: 90,
          align: "center",
        },
        {
          title: "整改期限",
          key: "limit_date",
          width: 100,
          align: "center",
        },
        {
          title: "整改落实状态",
          width: 140,
          align: "center",
          key: "risk_correct_stat",
          filters: [
            {
              label: "整改中",
              value: "整改中",
            },
            {
              label: "已完成",
              value: "已完成",
            },
            {
              label: "常态化管理",
              value: "常态化管理",
            },
          ],
          filterMethod(value, row) {
            return row.risk_correct_stat.indexOf(value) > -1;
          },
        },
        // {
        //   title: "整改确认状态",
        //   width: 140,
        //   align: "center",
        //   key: "confirm_stat",
        // },
        // {
        //   title: "确认意见",
        //   width: 100,
        //   align: "center",
        //   tooltool:'true',
        //   key: "confirm_stat",
        // },
        {
          title: "操作",
          align: "center",
          width: 140,
          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: {
                    size: "small",
                  },
                  style: { color: "green" },
                  on: {
                    click: () => {
                      this.itemEdit(params.row);
                    },
                  },
                },
                "修改"
              ),
              // h(
              //   "Button",
              //   {
              //     props: {
              //       size: "small",
              //     },
              //     style: { color: "blue" , marginLeft: "5px" },
              //     on: {
              //       click: () => {
              //         this.confirmEdit(params.row);
              //       },
              //     },
              //   },
              //   "确认"
              // ),
              h(
                "Button",
                {
                  props: {
                    size: "small",
                  },
                  style: { color: "red", marginLeft: "5px" },
                  on: {
                    click: () => {
                      this.confirmBox(params.row);
                    },
                  },
                },
                "删除"
              ),
            ]);
          },
        },
      ],
      loadingStatus: false,
      atimated: false,
      newCorrect: correctFac(),
      currentCorrect: correctFac(),
      ruleValidate: {
        line: [
          {
            required: true,
            type: "array",
            min: 2,
            message: "请选择线路！",
            trigger: "blur",
          },
        ],
        year: [
          {
            required: true,
            type: "date",
            message: "请选择评价时间！",
            trigger: "blur",
          },
        ],
        nextYear: [
          {
            required: true,
            type: "date",
            message: "请输入下次评价时间！",
            trigger: "blur",
          },
        ],
        customer: [
          { required: true, message: "请输入委托方名称！", trigger: "blur" },
          {
            type: "string",
            min: 6,
            message: "名称过短，请填写委托方全称！",
            trigger: "blur",
          },
        ],
        worker: [
          { required: true, message: "请输入受托方名称！", trigger: "blur" },
          {
            type: "string",
            min: 6,
            message: "名称过短，请填写受托方全称！",
            trigger: "blur",
          },
        ],
        type: [
          { required: true, message: "请输入报告类型！", trigger: "blur" },
        ],
        reportStat: [
          { required: true, message: "请选择报告状态！", trigger: "blur" },
        ],
        projectStat: [
          { required: true, message: "请选择项目状态！", trigger: "blur" },
        ],
        pdfFile: [
          {
            type: "string",
            required: true,
          },
        ],
      },
      ruleValidateAlter: {
        name: [
          {
            required: true,
            type: "string",
            min: 2,
            message: "请输入资料名称！",
            trigger: "blur",
          },
        ],
      },
      file: null,
      fileAlter: null,
      option: {
        correctStatChoices: [
          {
            value: "管控到位",
            label: "管控到位",
          },
          {
            value: "有待完善",
            label: "有待完善",
          },
          {
            value: "进行中",
            label: "进行中",
          },
        ],
        confirmStatChoices: [
          
        ],
      },
      scModal: false,
      swModal:false,
    };
  },
  watch: {},
  computed: {},
  created() {
    this.get_option_data();
  },
  methods: {
    get_option_data() {
      this.$axios
        .post(this.$url + "/static_option", {
          userToken: userToken,
          askData: "correctManage",
        })
        .then((response) => {
          this.option = response.data.res_record;
        });
    },
    handleReset(name) {
      this.$refs[name].resetFields();
      this.file = null;
      this.fileAlter = null;
    },
    handleRemove() {
      this.file = null;
      this.fileAlter = null;
    },
    handleSuccess() {},
    handleUpload(file) {
      if (
        file.name.split(".").pop() !== "pdf" &&
        file.name.split(".").pop() !== "doc" &&
        file.name.split(".").pop() !== "docx"
      ) {
        this.$Notice.warning({
          title: "文件格式不符",
          desc:
            "文件： " +
            file.name +
            " 的格式不正确，请选择后缀为“.pdf或.doc或.docx”的文件!",
        });
      } else if (file.size > 20480000) {
        this.$Notice.warning({
          title: "文件太大",
          desc: "文件：  " + file.name + " 的大小超出限制, 不能大于20MB!",
        });
      } else {
        this.file = file;
        this.newReport.pdfFile = file.name;
      }
      return false;
    },
    handleUploadAlter(file) {
      if (
        file.name.split(".").pop() !== "pdf" &&
        file.name.split(".").pop() !== "doc" &&
        file.name.split(".").pop() !== "docx"
      ) {
        this.$Notice.warning({
          title: "文件格式不符",
          desc:
            "文件： " +
            file.name +
            " 的格式不正确，请选择后缀为“.pdf或.doc或.docx”的文件!",
        });
      } else if (file.size > 10240000) {
        this.$Notice.warning({
          title: "文件太大",
          desc: "文件：  " + file.name + " 的大小超出限制, 不能大于10MB!",
        });
      } else {
        this.fileAlter = file;
        this.currentCorrect.pdfFile = file.name;
      }
      return false;
    },
    modal_save(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          let form = new FormData();
          form.append("file", this.fileAlter);
          form.append("userToken", userToken);
          form.append("askData", name);
          form.append("currentCorrect", JSON.stringify(this.currentCorrect));
          this.loadingStatus = true;
          let config = {
            headers: { "Content-Type": "multipart/form-data" },
          };
          this.$axios
            .post(this.$url + "/correct_alter", form, config)
            .then((response) => {
              this.loadingStatus = false;
              if (response.data.stat === "ok") {
                this.$Message.success("整改信息修改成功！");
                this.update_count++;
                this.scModal = false;
                this.swModal = false;
              } else {
                this.$Message.error(response.data.error_info);
              }
            })
            .catch((error) => {
              this.loadingStatus = false;
              this.$Message.error(error + "未知错误！");
            });
        } else {
          this.$Message.error("填入信息错误!");
        }
      });
    },
    modal_cancel() {
      this.scModal = false;
    },
    confirmBox(value) {
      this.$Modal.confirm({
        title: "确认清空该条风险点的整改记录：",
        content:
          "<p>" +
          "风险点名称：" +
          value.risk_content +
          "</p>" +
          "<p>" +
          "整改内容：" +
          value.correct_operation +
          "</p>",
        onOk: () => {
          this.delItem(value.id);
        },
        onCancel: () => {},
      });
    },
    delItem(id) {
      this.$axios
        .post(this.$url + "/correct_alter", {
          userToken: userToken,
          askData: "delCorrect",
          pid: id,
        })
        .then((response) => {
          if (response.data.stat === "ok") {
            this.$Message.success("删除成功！");
            this.update_count++;
          }
        })
        .catch((error) => {
          this.$Message.warning(error + "网络错误！操作失败。");
        });
    },
    itemEdit(value) {
      this.currentCorrect = correctFac();
      this.currentCorrect.id = value.id;
      this.currentCorrect.risk_id = value.risk_id;
      this.currentCorrect.line_name = value.line_name;
      this.currentCorrect.risk_content = value.risk_content;
      this.currentCorrect.major_system = value.major_system;
      this.currentCorrect.solution_proposal = value.solution_proposal;
      this.currentCorrect.correct_operation = value.correct_operation;
      this.currentCorrect.res_department = value.res_department;
      this.currentCorrect.res_leader = value.res_leader;
      this.currentCorrect.contact_phone = value.contact_phone;
      this.currentCorrect.limit_date = value.limit_date;
      this.currentCorrect.recent_goal = value.recent_goal;
      this.currentCorrect.recent_stat_description = value.recent_stat_description;
      this.currentCorrect.risk_correct_stat = value.risk_correct_stat;
      this.currentCorrect.pdfFile = null;
      this.fileAlter = null;
      this.scModal = true;
    },
    confirmEdit(value) {
      this.currentCorrect = correctFac();
      this.currentCorrect.id = value.id;
      this.currentCorrect.risk_id = value.risk_id;
      this.currentCorrect.line_name = value.line_name;
      this.currentCorrect.risk_content = value.risk_content;
      this.currentCorrect.correct_operation = value.correct_operation;
      this.currentCorrect.confirm_remark = value.confirm_remark;
      this.currentCorrect.confirm_stat = value.confirm_stat;
      this.swModal = true;
    },
  },
};
</script>
<style scoped>
.inner-wrapper {
  width: 100%;
  display: flex;
  display: -webkit-flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
  min-height: 700px;
}

.title-wrapper {
  text-align: center;
  font-size: 20px;
  padding: 16px;
}

.editor-wrapper,
.preview-wrapper {
  margin: 0px 20px 0px;
  width:1260px;
  font-size: 14px;
  text-align: center;
  padding: 10px 0;
}

.form-wrapper {
  text-align: left;
  border: 1px solid #eee;
  padding: 80px 120px 0px 50px;
  min-height: 600px;
  position: relative;
}

.sc-content-wrapper {
  padding: 0 50px;
  color: #333;
  font-size: 14px;
}
.choosed-product-wrapper {
  min-height: 100px;
  padding: 10px 20px;
  border: 1px solid #eee;
  box-shadow: 1px 1px 1px #eee;
  margin: 20px 0;
}

.sc-content-wrapper td:first-child {
  text-align: right;
  padding-right: 10px;
}

.sc-content-wrapper td {
  height: 45px;
  color: #777;
}

.sub-content-wrapper {
  margin: 10px 0;
  border: 1px solid #eee;
  position: relative;
}

.sub-content-title {
  position: absolute;
  left: 0;
  overflow: hidden;
  height: 100%;
  writing-mode: vertical-lr;
  text-align: center;
  background: #f5f5f5;
  letter-spacing: 10px;
  text-indent: 10px;
  padding: 12px 10px;
}

.table-wrapper {
  padding: 22.5px 20px;
  margin-left: 50px;
}

.fast-button {
  cursor: pointer;
}

input::-webkit-input-placeholder {
  color: #ccc;
  text-align: center;
}

input:-ms-input-placeholder {
  color: #ccc;
  text-align: center;
}

input::-moz-placeholder {
  color: #ccc;
  text-align: center;
}
tr.modal-product-choosed-tr {
  border-bottom: 1px solid #ddd;
}

tr.modal-product-choosed-tr:last-child {
  border: none;
}

input{
  display: inline-block;
    width: 100%;
    height: 32px;
    line-height: 1.5;
    padding: 4px 7px;
    font-size: 14px;
    border: 1px solid #dcdee2;
    border-radius: 4px;
    color: #515a6e;
    background-color: #fff;
    background-image: none;
    position: relative;
    cursor: text;
    transition: border .2s ease-in-out,background .2s ease-in-out,box-shadow .2s ease-in-out;
}

input:focus {
    border-color: #57a3f3;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(45,140,240,.2);
}

input:hover {
    border-color: #57a3f3;
}
</style>